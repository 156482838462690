/* You can add global styles to this file, and also import other style files */

@import './abstracts/variables';
@import './abstracts/utilities';
@import './components/badges';
@import './components/buttons';
@import './components/forms';
@import './components/navs';

@import '../../node_modules/bootstrap/scss/bootstrap.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  // Written in HSL format. Implementation started from shadcn/ui
  // and will be tweaked to fit our needs.
  // Per Ree's recommendation, we'll be changing these tokens to be in hex code
  :root {
    // Primary
    --primary-50: var(--ui-primary-50);
    --primary-100: var(--ui-primary-100);
    --primary-200: var(--ui-primary-200);
    --primary-300: var(--ui-primary-300);
    --primary-400: var(--ui-primary-400);
    --primary-500: var(--ui-primary-500);
    --primary-600: var(--ui-primary-600);
    --primary-700: var(--ui-primary-700);
    --primary-800: var(--ui-primary-800);
    --primary-900: var(--ui-primary-900);
    --primary-focus: var(--primary-focus);

    // Secondary
    --secondary-50: var(--ui-neutral-50);
    --secondary-100: var(--ui-neutral-100);
    --secondary-200: var(--ui-neutral-200);
    --secondary-300: var(--ui-neutral-300);
    --secondary-400: var(--ui-neutral-400);
    --secondary-500: var(--ui-neutral-500);
    --secondary-600: var(--ui-neutral-600);
    --secondary-700: var(--ui-neutral-700);
    --secondary-800: var(--ui-neutral-800);
    --secondary-900: var(--ui-neutral-900);

    // Neutral
    --neutral-50: var(--ui-neutral-50);
    --neutral-100: var(--ui-neutral-100);
    --neutral-200: var(--ui-neutral-200);
    --neutral-300: var(--ui-neutral-300);
    --neutral-400: var(--ui-neutral-400);
    --neutral-500: var(--ui-neutral-500);
    --neutral-600: var(--ui-neutral-600);
    --neutral-900: var(--ui-neutral-900);
    --neutral-950: var(--ui-neutral-950);

    // Information
    --info-50: var(--ui-info-50);
    --info-100: var(--ui-info-100);
    --info-200: var(--ui-info-200);
    --info-300: var(--ui-info-300);
    --info-400: var(--ui-info-400);
    --info-500: var(--ui-info-500);
    --info-600: var(--ui-info-600);
    --info-700: var(--ui-info-700);
    --info-800: var(--ui-info-800);
    --info-900: var(--ui-info-900);

    // Success
    --success-50: var(--ui-success-50);
    --success-100: var(--ui-success-100);
    --success-200: var(--ui-success-200);
    --success-300: var(--ui-success-300);
    --success-400: var(--ui-success-400);
    --success-500: var(--ui-success-500);
    --success-600: var(--ui-success-600);
    --success-700: var(--ui-success-700);
    --success-800: var(--ui-success-800);
    --success-900: var(--ui-success-900);

    // Warning
    --warning-50: var(--ui-warning-50);
    --warning-100: var(--ui-warning-100);
    --warning-200: var(--ui-warning-200);
    --warning-300: var(--ui-warning-300);
    --warning-400: var(--ui-warning-400);
    --warning-500: var(--ui-warning-500);
    --warning-600: var(--ui-warning-600);
    --warning-700: var(--ui-warning-700);
    --warning-800: var(--ui-warning-800);
    --warning-900: var(--ui-warning-900);

    // Error
    --error-50: var(--ui-error-50);
    --error-100: var(--ui-error-100);
    --error-200: var(--ui-error-200);
    --error-300: var(--ui-error-300);
    --error-400: var(--ui-error-400);
    --error-500: var(--ui-error-500);
    --error-600: var(--ui-error-600);
    --error-700: var(--ui-error-700);
    --error-800: var(--ui-error-800);
    --error-900: var(--ui-error-900);

    --background: white;
    --foreground: var(--ui-neutral-900);
    --card: var(--ui-neutral-50);
    --card-foreground: var(--ui-neutral-900);
    --popover: var(--background);
    --popover-foreground: var(--foreground);
    --primary: var(--ui-primary-500);
    --primary-foreground: var(--ui-neutral-50);
    --secondary: var(--ui-neutral-100);
    --secondary-foreground: var(--ui-neutral-900);
    --muted: var(--ui-neutral-100);
    --muted-foreground: var(--ui-neutral-500);
    --accent: var(--ui-neutral-100);
    --accent-foreground: var(--ui-neutral-900);
    --destructive: var(--ui-error-500);
    --destructive-foreground: var(--ui-neutral-50);
    --border: var(--ui-neutral-200);
    --input: var(--ui-neutral-200);
    --ring: var(--ui-neutral-300);
    --success: var(--ui-success-500);
    --success-foreground: var(--ui-neutral-50);
    --warning: var(--ui-warning-500);
    --warning-foreground: var(--ui-neutral-900);

    --radius: 0.5rem;
  }

  // Tailwind dark mode
  // https://tailwindcss.com/docs/dark-mode
  .dark {
    --primary-50: var(--ui-primary-950);
    --primary-100: var(--ui-primary-900);
    --primary-200: var(--ui-primary-800);
    --primary-300: var(--ui-primary-700);
    --primary-400: var(--ui-primary-600);
    --primary-500: var(--ui-primary-500);
    --primary-600: var(--ui-primary-400);
    --primary-700: var(--ui-primary-300);
    --primary-800: var(--ui-primary-200);
    --primary-900: var(--ui-primary-100);
    --primary-950: var(--ui-primary-50);

    --neutral-50: var(--ui-neutral-950);
    --neutral-100: var(--ui-neutral-900);
    --neutral-200: var(--ui-neutral-800);
    --neutral-300: var(--ui-neutral-700);
    --neutral-400: var(--ui-neutral-600);
    --neutral-500: var(--ui-neutral-500);
    --neutral-600: var(--ui-neutral-400);
    --neutral-700: var(--ui-neutral-300);
    --neutral-800: var(--ui-neutral-200);
    --neutral-900: var(--ui-neutral-100);
    --neutral-950: var(--ui-neutral-50);

    --info-50: var(--ui-info-950);
    --info-100: var(--ui-info-900);
    --info-200: var(--ui-info-800);
    --info-300: var(--ui-info-700);
    --info-400: var(--ui-info-600);
    --info-500: var(--ui-info-500);
    --info-600: var(--ui-info-300);
    --info-700: var(--ui-info-200);
    --info-800: var(--ui-info-100);
    --info-900: var(--ui-info-50);
    --info-950: var(--ui-info-50);

    --success-50: var(--ui-success-950);
    --success-100: var(--ui-success-900);
    --success-200: var(--ui-success-800);
    --success-300: var(--ui-success-700);
    --success-400: var(--ui-success-600);
    --success-500: var(--ui-success-500);
    --success-600: var(--ui-success-400);
    --success-700: var(--ui-success-300);
    --success-800: var(--ui-success-200);
    --success-900: var(--ui-success-100);
    --success-950: var(--ui-success-50);

    --warning-50: var(--ui-warning-950);
    --warning-100: var(--ui-warning-900);
    --warning-200: var(--ui-warning-800);
    --warning-300: var(--ui-warning-700);
    --warning-400: var(--ui-warning-600);
    --warning-500: var(--ui-warning-500);
    --warning-600: var(--ui-warning-400);
    --warning-700: var(--ui-warning-300);
    --warning-800: var(--ui-warning-200);
    --warning-900: var(--ui-warning-100);
    --warning-950: var(--ui-warning-50);

    --error-50: var(--ui-error-950);
    --error-100: var(--ui-error-900);
    --error-200: var(--ui-error-800);
    --error-300: var(--ui-error-700);
    --error-400: var(--ui-error-600);
    --error-500: var(--ui-error-500);
    --error-600: var(--ui-error-400);
    --error-700: var(--ui-error-300);
    --error-800: var(--ui-error-200);
    --error-900: var(--ui-error-100);
    --error-950: var(--ui-error-50);

    --background: var(--ui-neutral-900);
    --foreground: white;
    --card: var(--ui-neutral-900);
    --card-foreground: var(--ui-neutral-50);
    --popover: var(--background);
    --popover-foreground: var(--foreground);
    --primary: var(--ui-primary-500);
    --primary-foreground: var(--ui-neutral-50);
    --secondary: var(--ui-neutral-900);
    --secondary-foreground: var(--ui-neutral-100);
    --muted: var(--ui-neutral-500);
    --muted-foreground: var(--ui-neutral-100);
    --accent: var(--ui-neutral-900);
    --accent-foreground: var(--ui-neutral-100);
    --destructive: var(--ui-error-500);
    --destructive-foreground: var(--ui-neutral-50);
    --border: var(--ui-neutral-300);
    --input: var(--ui-neutral-300);
    --ring: var(--ui-neutral-200);
    --success: var(--ui-neutral-50);
    --success-foreground: var(--ui-success-500);
    --warning: var(--ui-neutral-900);
    --warning-foreground: var(--ui-warning-500);

    .react-loading-skeleton {
      --base-color: var(--ui-neutral-800);
      --highlight-color: var(--ui-neutral-700);
    }
  }
}

@layer base {
  body {
    @apply tw-bg-background tw-text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

// Allow percentage based heights
html,
body,
#app {
  height: 100%;
}

// App shell
.layout-dashboard-container {
  display: grid;
  grid-template-areas:
    'header'
    'page';
  grid-template-columns: minmax(0, 1fr); // Stop columns from overflowing
  grid-template-rows: auto minmax(0, 1fr); // Allow header to take up the space it requires, and then the remaining row takes the rest
  height: 100%;

  .app-header {
    grid-area: header;
  }

  .app-side-nav {
    grid-area: dashboard;
    display: none;
    transition: 0.2s ease-in-out;
  }

  main {
    grid-area: page;
  }
}

@include media-breakpoint-up(md) {
  .layout-dashboard-container {
    grid-template-areas:
      'header header'
      'dashboard page';
    grid-template-columns: max-content minmax(0, 1fr); // Stop columns from overflowing

    .app-side-nav {
      display: initial;
    }
  }
}

// Components
.sc-white-box,
.sc-white-border-box {
  background-color: white;
  border-radius: 15px;
  padding: 25px;
}

.sc-white-border-box {
  border: solid $border-color;
  border-width: $border-width;
}

.sc-shadow {
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
}

.btn-secondary {
  border: 1px solid $gray-300;
}
.btn-secondary:hover,
.btn-secondary:focus {
  border: 1px solid $gray-300;
  background-color: shift-color($white, 10%);
}
.btn-secondary:disabled {
  opacity: 1;
  background-color: $gray-200;
}

.nav-pills .nav-link.active {
  border-bottom: 2px solid var(--sc-primary);
}

// Backgrounds
.sc-static-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: $accent;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

.sc-static-page,
.sc-page-wrapper {
  height: auto;
  position: relative;
}

.form-invalid-feedback {
  position: absolute;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.typeahead-scrollable {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.typeahead-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: var(--sc-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.typeahead-item--active,
.typeahead-item:active,
.typeahead-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: var(--sc-primary);
}

// Order status
// TODO: Colocate with component once build system has been configured to understand css file imports in components.
.order-status {
  display: inline-block;
  padding: 0.325rem 0.5rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.order-status__in-process {
  $blue: #347bcc;
  background-color: $blue;
}

.order-status__canceled {
  $red: #d65454;
  background-color: $red;
}

.order-status__shipped {
  background-color: var(--sc-success);
}
// End: Order status

@media print {
  h4 {
    font-size: 14px;
  }

  h6,
  p,
  li {
    font-size: 10px;
  }

  .sc-white-border-box {
    padding-bottom: 8px !important;
  }
}

// Bootstrap utility overrides
.outline-focus-none:focus,
.outline-focus-none:active,
.outline-focus-none:active:focus {
  outline: none;
  border-color: white;
}

// Generic component styles
// Input

// For wrapping divs to match focused input styles
.sc-input-wrapper--focused {
  color: var(--sc-body-color);
  background-color: var(--sc-body-bg);
  border-color: #bbaaeb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(119, 84, 214, 0.25);
}

// TODO: Translate these to colocated Tailwind styles

// Autocomplete
.outline-none {
  outline: none;
}

.autocomplete-container:focus {
  border-color: var(--sc-gray-300);
  box-shadow: none;
}

.autocomplete-border {
  color: var(--sc-gray-900);
  background-color: #fff;
  border-color: #bbaaeb;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #7754d640;
}

.autocomplete-selection {
  opacity: 0.8;
}

// Deliberately chose to not scope
// by hover selector as active states
// via keyboard or mouse are tracked by Headless so
// we want a class we can control when to conditionally apply
.autocomplete-selection--hover {
  background-color: var(--sc-accent);
  cursor: pointer;
}

// Select
.dropdown-container {
  outline: none;
  max-height: 250px;
  overflow-y: scroll;
}

.dropdown-selection {
  opacity: 0.8;
}

.dropdown-selection--hover {
  background-color: var(--sc-accent);
  cursor: pointer;
}

// Tooltip
.tooltip {
  .tooltip-inner {
    max-width: 100%;
    padding: 16px;
  }
}

// Collapsibles
.collapsible:active,
.collapsible:disabled {
  // Override bootstrap active state style. !important
  // is needed as the bootstrap selector has high specificity.
  // This also assumes the backdrop is the same color.
  border-color: white !important;
}
.collapsible:disabled {
  cursor: not-allowed;
}

.collapsible[aria-expanded='true'] {
  i.bi.bi-chevron-right::before {
    transform: rotate(90deg);
  }
}

// Side drawer
.dialog-container {
  position: relative;
  z-index: 1100;
}

.drawer-toggle-animation {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.drawer-panel-animation {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.drawer-panel-animation--translate-x-full {
  transform: translateX(100%);
}

.drawer-panel-animation--translate-x-0 {
  transform: translateX(0px);
}

.drawer-overlay {
  position: fixed;
  inset: 0px;
  background-color: var(--sc-gray-700);
}

.drawer-inset {
  inset: 0px;
}

.drawer-vertical-inset {
  top: 0px;
  bottom: 0px;
}

// Order creation suggestions
.lens-options-suggestions-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 200px));
  row-gap: 0.5rem;
  column-gap: 1rem;
}

// Order creation more rx options
.more-rx-options-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 16px;
  column-gap: 16px;
  align-items: center;
}

// Writable RXTable
.writable-rx-table {
  border-collapse: separate; // Needed for extra highlight
  border-spacing: 0; // Stops unnecessary spacing caused from separate borders

  th {
    text-align: center;
    font-weight: 400;
    padding: 8px;
  }

  td {
    padding: 8px;
  }

  td input {
    min-width: 4rem;
    max-width: 6rem;
  }
}

.lens-thickness-placement {
  min-width: 140px;
}

// Marking required fields in the rx table
.writable-rx-table--required-header::after {
  content: '*';
  color: red;
  margin-left: 2px;
}

// Gradient mask
.gradient-mask {
  mask-image: linear-gradient(to bottom, black 0%, black 10%, rgba(0, 0, 0, 0.1) 30%);
  pointer-events: none;
}

// For rx columns with paired rows when tabbing (e.g. PD)
.grouped-rx {
  background-color: #f3f5f8;
}

// Shared: Order details and order confirmation
.odetails-overview {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  column-gap: 1em;
}

// 50-50 width split between instructions and services
@include media-breakpoint-up(sm) {
  .instructions-services {
    height: 200px;
    overflow-y: auto;
  }

  .instructions-services--split {
    & > div {
      width: 50%;
    }
  }
  // If either special instructions or services is empty,
  // just show the populated one and expand it to max-width.
  .instructions-services--full-width {
    & > div {
      width: 100%;
    }
  }
}

// Collapse instructions and services sections
// vertically on smaller screens
@include media-breakpoint-down(sm) {
  .instructions-services--split {
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  // If either special instructions or services is empty,
  // just show the populated one and expand it to max-width.
  .instructions-services--full-width {
    & > div {
      width: 100%;
    }
  }
}

.loading-overlay {
  opacity: 90%;
  border-top-left-radius: var(--sc-modal-inner-border-radius);
  border-top-right-radius: var(--sc-modal-inner-border-radius);
}

.odetails-container {
  height: 100%;

  .odetails-large-column {
    grid-column: span 2;
  }

  .odetails-column-title {
    opacity: 0.5;
  }

  .odetails-overview {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 1em;
  }

  .odetails-lab-updated-date {
    grid-column: auto;
  }

  .odetails-rx-container {
    .odetails-rx-header {
      opacity: 0.5;
    }

    @media print {
      .odetails-rx-header--print-only {
        color: black;
        opacity: 1;
        font-size: 10px;
      }

      .odetails-rx-cell--print-only {
        font-size: 10px;
      }
    }
  }

  // Order options
  i.bi.bi-chevron-down::before {
    font-weight: 700 !important;
    font-size: 0.8rem;
  }

  ///////////////////
  // Media Queries //
  ///////////////////

  // 50-50 width split between instructions and services
  @include media-breakpoint-up(sm) {
    .odetails-instructions-services {
      height: 200px;
      overflow-y: auto;
    }

    .odetails-instructions-services--split {
      & > div {
        width: 50%;
      }
    }
    // If either special instructions or services is empty,
    // just show the populated one and expand it to max-width.
    .odetails-instructions-services--full-width {
      & > div {
        width: 100%;
      }
    }
  }

  // Collapse instructions and services sections
  // vertically on smaller screens
  @include media-breakpoint-down(sm) {
    .odetails-instructions-services--split {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }

    // If either special instructions or services is empty,
    // just show the populated one and expand it to max-width.
    .odetails-instructions-services--full-width {
      & > div {
        width: 100%;
      }
    }
  }

  @media print {
    .order-details-container {
      // Prevent overflow from clipping instead print on next page.
      overflow: visible;
      // Spacing not needed in print media as it comes with default margins
      padding-top: 0px !important;
      // Ensure that colors do not get modified for the SpecCheck TM
      print-color-adjust: exact; // Firefox
      -webkit-print-color-adjust: exact; // Chrome, Safari, Edge, Opera
    }

    .odetails-column-title {
      // Makes the titles darker for printers to properly print out the labels
      opacity: 1;
    }

    .odetails-instructions-services {
      // Prevent overflow from clipping instead print on next page.
      overflow: visible;
      // Override the 200px height
      height: auto;
    }

    // Override collapse rule defined above.
    .odetails-instructions-services--split {
      flex-direction: row;
      & > div {
        width: 50%;
      }
    }

    // If either special instructions or services is empty,
    // just show the populated one and expand it to max-width.
    .odetails-instructions-services--full-width {
      & > div {
        width: 100%;
      }
    }
  }
}

.packing-slip-container {
  height: 100%;

  .ps-column-title {
    opacity: 0.5;
  }

  .prescription-details {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 1em;
  }

  .ps-logo {
    height: 45px;
  }

  @media print {
    .ps-container {
      // Ensure that colors do not get modified for the SpecCheck TM
      print-color-adjust: exact; // Firefox
      -webkit-print-color-adjust: exact; // Chrome, Safari, Edge, Opera
      // Spacing not needed in print media as it comes with default margins
      padding-top: 0px !important;
    }

    .ps-info {
      // Display three fields per row on print
      grid-template-columns: auto auto auto;
    }

    .ps-column-title {
      // Makes the titles darker for printers to properly print out the labels
      opacity: 1;
    }
  }
}

.rewards-container {
  height: 100%;

  .rewards-tracker-container {
    bottom: 3em;
  }

  .rewards-tracker-container {
    display: grid;
    grid-template-columns: 0.5fr 2fr 0.5fr 0.1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 1rem;
    align-items: center;
    // Copying area names forces area span
    grid-template-areas:
      'rewards-copy progress-bar orders-count .'
      'rewards-copy progress-bar reset-copy .';
  }

  .rewards-steps-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: minmax(0, 1fr) minmax(0, 0.5fr) minmax(0, 1fr) minmax(0, 0.5fr) minmax(0, 1fr);
    row-gap: 16px;
    grid-template-areas:
      'orders-icon first-arrow calendar-icon second-arrow credit-card-icon'
      // . indicates empty cell
      'orders-copy . calendar-copy . credit-card-copy';
    align-items: center;
    justify-content: center;
  }

  .icon-container {
    height: 60px;
    width: 60px;
  }

  // Mobile
  @include media-breakpoint-down(md) {
    .rewards-tracker-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      row-gap: 8px;
      grid-template-areas:
        'rewards-copy orders-count'
        'progress-bar progress-bar'
        'reset-copy reset-copy';
      align-items: center;
      justify-items: 'center';
    }

    .rewards-steps-container {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      row-gap: 24px;
      grid-template-areas:
        'orders-icon'
        'orders-copy'
        'first-arrow'
        'calendar-icon'
        'calendar-copy'
        'second-arrow'
        'credit-card-icon'
        'credit-card-copy';
      align-items: center;
      justify-content: center;
    }

    .arrow-icon {
      transform: rotate(0.25turn);
    }
  }
}

// Orders Overview
.SearchPopover {
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
}

// Sidebar
.office-acct-box-btn {
  padding: 0.75rem;
  background-image: radial-gradient(circle at 100% 100%, transparent 3px, #8f6bed 3px, #8f6bed 5px, transparent 5px),
    linear-gradient(to right, #8f6bed, #8f6bed),
    radial-gradient(circle at 0% 100%, transparent 3px, #8f6bed 3px, #8f6bed 5px, transparent 5px),
    linear-gradient(to bottom, #8f6bed, #c440d3),
    radial-gradient(circle at 0% 0%, transparent 3px, #c440d3 3px, #c440d3 5px, transparent 5px), linear-gradient(to left, #c440d3, #c440d3),
    radial-gradient(circle at 100% 0%, transparent 3px, #c440d3 3px, #c440d3 5px, transparent 5px),
    linear-gradient(to top, #c440d3, #8f6bed);
  background-size:
    5px 5px,
    calc(100% - 10px) 2px,
    5px 5px,
    2px calc(100% - 10px);
  background-position:
    top left,
    top center,
    top right,
    center right,
    bottom right,
    bottom center,
    bottom left,
    center left;
  background-repeat: no-repeat;
  text-align: left;
  width: 100%;
}

.office-acct-address {
  white-space: pre-line; // preserve whitespace from string; needed for address pipe
}

// Nav bar

// Brand Name & Logo
.navbar-brand {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.header-logo {
  width: calc(3rem + 0.3vw);
}

// Navbar & Profile Button Styling
// Remove border
#profile-button,
#navbar-toggler {
  border: 0;
}

// Remove padding for each button relative to its position
#profile-button {
  padding-right: 0;
}
#navbar-toggler {
  padding-left: 0;
}

// Remove Bootstrap default styles
#profile-button:focus,
#profile-button:active,
#navbar-toggler:focus,
#navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

// Dropdown Menu Styling
.dropdown-menu {
  z-index: $zindex-fixed; // Need z-index precedence because `/create-order` uses a sticky element at the top of page
}

.office-acct {
  padding: 12px;
}

.office-acct {
  background-color: unset;
}

@include media-breakpoint-up(md) {
  .dropdown-item:hover {
    background-color: var(--sc-accent);
  }
}

i.bi.bi-chevron-down::before {
  font-weight: 700 !important;
  font-size: 0.8rem;
}

.dashboard-link {
  display: block;
  color: var(--sc-gray-600);
  text-decoration: none;
  padding: 0.375rem 0.75rem;
  border-radius: 0.5rem;
  white-space: normal;
  margin-bottom: 0.25rem;
}

.dashboard-link:hover {
  cursor: pointer;
}

.dashboard-link--active {
  background-color: var(--sc-accent);
  color: var(--sc-primary);
}

// Tailwind base styles that clash with bootstrap's. Once bootstrap is gone we can remove this. Alternatively,
// there might be a way to have tailwind's styles take precedence but my css specificity escapes me at
// the time of writing this diff.
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

// ####### Bootstrap tables
// General table styles
.table-general {
  // Extend the table-hover styles to all tables
  @extend .table-hover;

  // The table's header
  thead {
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: inset 0 -2px 0 0 var(--sc-gray-200);

    tr th {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  // The table's body
  tbody {
    tr {
      text-align: left;

      td {
        padding: 1rem 0.5rem;
        vertical-align: middle;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  // Hide tables marked only for desktop
  .table-desktop {
    display: none;
  }

  // Remove color on hover as its not nocessary for mobile
  table.table-hover tbody tr:hover {
    --sc-table-hover-bg: transparent;
  }
}

@include media-breakpoint-up(md) {
  // Hide tables meant for mobile
  .table-mobile {
    display: none;
  }

  // Add the SpecCheck accent color to the tables on hover
  table.table-hover tbody tr:hover {
    --sc-table-hover-bg: var(--sc-accent);
  }
}
